import React from "react"
import { graphql, Link } from "gatsby"
import { Styled } from "theme-ui"
import { Helmet } from "react-helmet"
import Header from "../components/Header"
import Footer from "../components/Footer"

import "../pages/index.css"

export default ({data}) => {

console.log(data)
  
  return (
    <Styled.root>
      <main>
        <div className="container">
        <Header />
        <Helmet>
              <title>All Areas - Your Local Area</title>
              <html lang={`en`} />
            <meta
                 name="description"
                 content={`All areas covered in England and Wales.`}
            />
              </Helmet>
            <div class="centreMe">  
              <h1>All Areas</h1>
              <h2>Countries</h2>  
            <ul class="bullet">
            {data.allLookCountriesCsv.nodes.map((node) => (
              <li><Link to={node.Slug}>{node.Name}</Link></li>
            ))}
            </ul>
            <h2>Regions</h2>  
            <ul class="bullet">
            {data.allLookRegionsCsv.nodes.map((node) => (
              <li><Link to={node.Slug}>{node.Name}</Link></li>
            ))}
            </ul>
            <h2>Counties</h2>  
            <ul class="bullet">
            {data.allLookCountiesCsv.nodes.map((node) => (
              <li><Link to={node.Slug}>{node.Name}</Link></li>
            ))}
            </ul>
            <h2>London Boroughs</h2>  
            <ul class="bullet">
            {data.allLookDistrictsCsv.nodes.map((node) => (
              <li><Link to={node.Slug}>{node.Name}</Link></li>
            ))}
            </ul>
            <h2>Towns and Cities</h2>  
            <ul class="bullet">
            {data.allLookCitiesCsv.nodes.map((node) => (
              <li><Link to={node.Slug}>{node.Name}</Link></li>
            ))}
            </ul>
            </div>
          <Footer />
        </div>
      </main>
    </Styled.root>
  )
}

export const query = graphql`
  query WgygEv {
    allLookCountriesCsv {
      nodes {
        Name
        Slug
      }
    }
    allLookRegionsCsv {
        nodes {
          Name
          Slug
        }
      }
      allLookCountiesCsv {
        nodes {
          Name
          Slug
        }
      }
      allLookDistrictsCsv {
        nodes {
          Name
          Slug
        }
      }
      allLookCitiesCsv {
        nodes {
          Name
          Slug
        }
      }
  }`